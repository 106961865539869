<template>
    <div class="wpb_wrapper">
    <div
      class="footer_wrapper"
    >
      <div class="text_container">
        <p class="footer_title">
          Quer saber mais sobre as soluções da Koin para o seu e-commerce?
        </p>
        <p class="footer_paragraph">
          Conheça todas as vantagens e ofereça agora mesmo a opção de pagamento por PIX e boleto parcelado.
        </p>
      </div>

        <a
          class="support_button"
          href="https://materiais.koin.com.br/formulario-site-lojas"
          target="_blank"
          rel="noopener external noreferrer"
          data-wpel-link="external"
          >Saiba mais</a
        >
    </div>
  </div>
</template>

<style lang="scss">
.footer_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 268px;
  background: #282828;
  padding: 0 8px;
  margin-top: 2px;
}
.wpb_wrapper {
  margin-top: 76px;
}
.text_container {
  width: 100%;
  @media screen and (min-width: 400px) {
    width: 400px;

  }
}

.footer_title {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
}

.footer_paragraph {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 4px;
  margin-bottom: 32px;
}

.support_button {
  padding: 14px 30px;
  text-decoration: none;
  width: 167px;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;

  color: #000000;
}
</style>