<template>
  <content-wrapper>
    <div class="configure">
      <form class="configure__form" @submit="onSubmit">
        <img class="configure__logo" src="@/assets/koin-logo.png" alt="koin logo"/>
        <p class="instruction">Insira seu login e senha para prosseguir com a liberação de parcelamento da Koin.</p>
        <div class="input__container">
          
          <input type="checkbox" id="checkbox" v-model="checked" />
          <label for="checkbox" class="contact-check">Eu configurei o telefone como obrigatório no checkout conforme <a rel="stylesheet" href="/help"> tutorial </a></label>
          <br>
          <br>
          <input-field
              v-if="checked"
              v-model="store_code"
              name="store_code"
              type="text"
              label="Código da Loja"
              placeholder="Insira o Store Code fornecido"
              
          />
          <input-field
              v-if="checked"
              v-model="account"
              name="account"
              type="text"
              label="Número da Conta"
              placeholder="Insira o número da sua conta (account)"
          />
          <input-field
              v-if="checked"
              v-model="fingerprint_org_id"
              name="fingerprint_org_id"
              type="text"
              label="Código Fingerprint"
              placeholder="Insira o código fingerprint"
          />
          <input-field
              v-if="checked"
              v-model="private_key"
              name="private_key"
              type="password"
              label="Chave Privada "
              placeholder="Insira sua Chave Privada (private_key)"
          />
        </div>
        <div class="email-container">
          <button 
              v-if="checked" class="no-access" type="button" v-on:click="show = !show">
            Não tem acesso?
          </button>
          <transition name="fade">
            <div v-if="show" class="contact-message">
              <p class="message">Favor entrar em contato com</p>
              <p class="email">comercial@koin.com.br</p>
            </div>

          </transition>
        </div>
        <div  v-if="checked" >
          <loader v-if="loading" />
          <button v-else class="configure__submit" type="submit">Entrar</button>
        </div>
        <p v-if="!loading" class="contact-info">Caso você possua qualquer dúvida,
          entre em contato com o nosso time pelo email <span class="email">comercial@koin.com.br</span></p>
      </form>
    </div>
  </content-wrapper>
  <the-footer />
  <div>  
    <div v-if="showModal" class="modal-container" >
      <div class="modal-content"  @click.stop >
        <div class="row">
          <img src="https://www.koin.com.br/wp-content/themes/koinbr/dist/img/logotipo-grande.webp">
          <div class="col-md-12">
            <button class="modal-button"  @click="fadeOut">Já sou cliente Koin</button>
            <!-- <button class="modal-button" @click="fadeOut">Já sou cliente Koin</button> -->
          </div>
          <div class="col-md-12">
            <button class="modal-button"  @click="openKoinWebsite" target="_blank">Quero ser cliente Koin</button>
          </div>
        </div>
        
        
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/ui/InputField";
import axios from "axios";
import Loader from "@/components/ui/Loader.vue";
import TheFooter from "@/components/ui/TheFooter.vue";
import {resolveError} from '@/utils/errutils'
import ContentWrapper from "@/components/ui/ContentWrapper";

export default {
  data() {
    return {
      store_code: "",
      private_key: "",
      account: "",
      fingerprint_org_id: "",
      loading: false,
      show: false,
      checked: false,
      showModal: true,
    };
  },
  components: {
    ContentWrapper,
    InputField,
    Loader,
    TheFooter,
  },
  mounted() {
    this.getFirstToken();
    let existingScriptTag = document.getElementById('deviceId_fp');
    if (!existingScriptTag) {
    let script = document.createElement('script')
      script.setAttribute('src', 'https://securegtm.despegar.com/risk/fingerprint/statics/track-min.js')
      script.id = "deviceId_fp";
      script.setAttribute("org_id", "WuilpwU3Rg");
      document.head.appendChild(script)
      
    }
  },
  methods: {
    async getFirstToken() {
      if (this.loading) return;
      this.loading = true;
      try {
        let { data } = await axios.get("/tokens/first", {
          headers: {
            "X-SESSION": this.$session.getSession(),
          },
        });

        if (data.token) {
          this.$session.setCSRF(data.token);
        }
      } catch (error) {
        let resolvedError = resolveError(error.response);
        this.$alert.setAlert(resolvedError.text, resolvedError.interval);
        if (resolvedError.redirect) {
          this.$router.push(resolvedError.redirect);
        }
      } finally {
        this.loading = false;
      }
    },
    async onSubmit(event) {
      if (this.loading) return;
      event.preventDefault();
      this.loading = true;
      try {
        let { data } = await axios.post(
          "/configurations",
          {
            config_id: this.$route.query.id,
            store_code: this.store_code,
            private_key: this.private_key,
            fingerprint_org_id: this.fingerprint_org_id,
            account: this.account,
          },
          {
            headers: {
              "X-SESSION": this.$session.getSession(),
              "X-CSRF-TOKEN": this.$session.getCSRF(),
            },
          }
        );
        this.$router.push(`/confirm?redirect=${data.store_url}`);
      } catch (error) {
        if (error?.response?.headers) {
          if (error.response.headers["x-csrf-token"]) {
            this.$session.setCSRF(error.response.headers["x-csrf-token"]);
          }
        }

        let resolvedError = resolveError(error.response);
        this.$alert.setAlert(resolvedError.text, resolvedError.interval);
        if (resolvedError.redirect) {
          this.$router.push(resolvedError.redirect);
        }
      } finally {
        this.loading = false;
      }
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    fadeOut() {
      // Lógica para o fade out
      this.showModal = false;
    },
    openKoinWebsite() {
      window.open("https://www.koin.com.br/bnplb2b/", "_blank");
    },
  },
};
</script>

<style lang="scss">
.configure__form {
  text-align: center;
  width: 100%;
  height: 100%;
}

.configure {
  width: 100%;
  padding: 32px;
  @media screen and (min-width: 450px) {
    width: 400px;
    height: 790px;
  }
}

.email-container {
  height: 40px;
}

.no-access {
  background-color: white;
  border: none;
  display: flex;
  font-family: inherit;
  font-size: 14px;
  justify-content: flex-start;
  color: #2c3e50;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.contact-message {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.message {
  font-size: 12px;
}

.email {
  padding: 0 4px;
  margin-left: 4px;
  border-radius: 12px;
  font-size: 12px;
  border: 1px solid #9996af;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.configure__logo {
  width: 100%;
  max-width: 140px;
  align-self: center;
  margin-bottom: 14px;
  margin-top: 20px;
}

.instruction {
  width: 100%;
  color: #666666;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 32px;
}

.configure__submit {
  padding: 15px 10px;

  border: none;
  width: 100%;

  cursor: pointer;
  height: 54px;
  margin-bottom: 32px;
  background: #13ea13;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}

.contact-check {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  color: #666666;
  margin: 0 10px
}
.contact-info {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #666666;
  margin: 0 10px
}

.email {
  color: #2e56e0;
}
</style>

<style scoped>
.modal-container a{
  text-decoration: none;
}
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  width: 60%;
  height: 29%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 340px;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.row img {
    align-self: flex-start;
    width: 50px;
    margin-bottom: 50px;
}
@media (max-width: 442px) {
  .row img{ 
    margin-bottom: 10px!important;
  }
}
.col-md-12 {
  width: 100%; 
}

.modal-button {    
    background: #fff;
    border: 1px solid #ccc;
    color: #000;
    padding: 10px 20px;
    margin: 10px 0;
    border-radius: 5px;
    cursor: pointer;
    width: 37%;
    min-width: 220px;
    text-transform: uppercase;
}

 
</style>