import Vue, {reactive} from 'vue'
import Alert from '@/components/ui/Alert.vue';

const createAlert = () => ({
  data: reactive({
    alert: '',
    dismiss: -1,
  }),
  setAlert(alert: string, dismiss?: number) {
    if (this.data.dismiss != -1) {
        clearTimeout(this.data.dismiss)
    }

    this.data.alert = alert
    console.log({dismiss})
    if (dismiss) {
        console.log('something')
        this.data.dismiss = setTimeout(this.clearAlert.bind(this), dismiss * 1000)
    }
  },
  clearAlert() {
    this.data.alert = ''
    this.data.dismiss = -1
  },
  getAlert(): string {
    return this.data.alert
  }
})

export const instance = createAlert()

export default {
  install:  (app: Vue.App, ...options: any[]) => {
    app.config.globalProperties.$alert = instance
    app.component('alert', Alert)
  }
}