import Vue from 'vue'

const createSession = () => ({
  data: {
    session: '',
    csrf: ''
  },
  setSession(session: string) {
    this.data.session = session
  },
  getSession() {
    return this.data.session
  },
  setCSRF(csrf: string) {
    return this.data.csrf = csrf
  },
  getCSRF() {
    return this.data.csrf
  }
})

export const instance = createSession()

export default {
  install:  (app: Vue.App, ...options: any[]) => {
    app.config.globalProperties.$session = instance
  }
}