<template>
    <div class="content-wrapper">
        <div class="content-wrapper__content">
            <slot />
        </div>
    </div>
</template>

<style lang="scss">
.content-wrapper {
  min-height: calc(100vh - 268px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  width: 100%;
  box-sizing: border-box;
  &__content {
    background: #FFFFFF;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    width: 100%;
    @media screen and (min-width: 450px) {
      border-radius: 10px;
      width: unset;
    }
  }
}
</style>