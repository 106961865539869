<template>
  <div class="page">
    <div class="header">
      <img class="logo" src="../assets/koinx-logo-branca.png" alt="koin logomarca"/>
    </div>
    <div class="container">
      <p class="description">
        A <strong>Koin</strong> é uma fintech de crédito instantâneo, feita especialmente para você que precisa de uma
        alternativa aos meios atuais de pagamento parcelado.
      </p>
      <div class="advantages">
        <p class="advantages_title">
          Vantagens da Koin:
        </p>
        <div class="advantages_card-row">
          <div class="advantages_card">
            <div class="circle">
              <img src="../assets/heart_list.svg" class="advantages_icon" alt="heart payment"/>
            </div>
            <p class="advantage_card_title">Pagamento parcelado sem o uso de cartão de crédito</p>
            <p class="advantage_card_description">
              Ofereça a possibilidade de parcelamento em até 24x sem o uso de cartão de crédito e
              aumente a conversão dos seus pedidos.
            </p>
          </div>
          <div class="advantages_card">
            <div class="circle">
              <img src="../assets/money_clock.svg" class="advantages_icon" alt="heart payment"/>
            </div>
            <p class="advantage_card_title">Análise de crédito instantânea</p>
            <p class="advantage_card_description">
              Assim que o seu cliente finalizar o cadastro da Koin, já é realizada a análise dos dados. O pedido é
              aprovado em poucos minutos.
            </p>
          </div>

          <div class="advantages_card">
            <div class="circle">
              <img src="../assets/chart.svg" class="advantages_icon" alt="heart payment"/>
            </div>
            <p class="advantage_card_title">Incremento dos pedidos do seu negócio</p>
            <p class="advantage_card_description">
              Aumente o ticket médio dos seus pedidos ao oferecer a Koin como opção de pagamento.
              Você pode vender ainda mais com o PIX e Boleto Parcelado.
            </p>
          </div>

          <div class="advantages_card">
            <div class="circle">
              <img src="../assets/money_calendar.svg" class="advantages_icon" alt="heart payment"/>
            </div>
            <p class="advantage_card_title">Receba a comissão das suas vendas integralmente</p>
            <p class="advantage_card_description">
              Assim que houver a aprovação do pedido de parcelamento e confirmação do pedido, você recebe o valor do
              pedido integralmente.
            </p>
          </div>
        </div>

        <a type="button" :href="redirect" class="advantages_confirm-button">Confirmar</a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import Loader from "@/components/ui/Loader.vue";
import TheFooter from "@/components/ui/TheFooter.vue";
import ContentWrapper from "@/components/ui/ContentWrapper.vue";

export default defineComponent({
  name: "",
  components: {
    Loader,
    TheFooter,
    ContentWrapper,
  },
  computed: {
    redirect() {
      return `https://${this.$route.query.redirect}`;
    },
  },
});
</script>

<style lang="scss">
.page {
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 450px) {
    height: 100vh;
  }
}

.header {
  width: 100%;
  height: 94px;
  background: #13ea13;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  width: 140px
}

.container {
  width: 100%;
  height: calc(100vh - 94px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  padding: 0 8px;
  max-width: 580px;
  margin: 64px auto 45px;
  line-height: 27px;
  color: #666666;
  @media screen and (min-width: 800px) {
    margin: 64px auto 70px;
  }
}

.advantages {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  padding: 32px;
  @media screen and (min-width: 450px) {
    border-radius: 10px;
    max-width: 600px;
  }
  @media screen and (min-width: 1146px) {
    max-width: 1046px;
    height: 378px;
    padding: 32px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

.advantages_title {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #666666;
  padding-bottom: 32px;
}

.advantages_card-row {
  @media screen and (min-width: 1146px) {
    display: flex;

  }
}

.advantages_card {
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 1146px) {
    margin-right: 48px;
    align-items: flex-start;
    width: 196px;
  }
  &:last-child {
    margin-right: 0;
  }
}

.advantage_card_title {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #4285F4;
  width: 80%;
  margin: 8px 0 4px 0;
  @media screen and (min-width: 1146px) {
    text-align: left;
    width: 100%;
    margin-bottom: 8px;
  }
}

.advantage_card_description {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: #666666;
  text-align: center;
  @media screen and (min-width: 1146px) {
    text-align: left;
  }
}

.circle {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background: #E3ECFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.advantages_icon {
  height: 18px;
}

.advantages_confirm-button {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  background: #13ea13;
  color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;

  @media screen and (min-width: 1146px) {
    width: 336px;
    height: 54px;
    background: #13ea13;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }
}

//.confirm {
//  color: #3a3f73;
//  display: flex;
//  flex-direction: column;
//  text-align: left;
//  @media screen and (max-height: 1200px) and (min-width: 1000px){
//    width: 900px;
//  }
//  @media screen and (min-width: 1000px) and (min-height: 1200px) {
//    width: 500px
//  }
//}
//
//.confirm__logo {
//  width: 100%;
//  max-width: 300px;
//  height: auto;
//  align-self: center;
//  margin-bottom: 30px;
//}
//
//.paragraph__container {
//  display: flex;
//  flex-direction: column;
//  justify-content: space-between;
//  @media screen and (max-height: 1200px) and (min-width: 1000px){
//    flex-direction: row;
//    padding: 12px 0
//  }
//}
//
//.icon {
//  height: 55px;
//  max-width: 55px;
//}
//
//.confirm__header {
//  display: flex;
//  flex-direction: row;
//  padding-top: 40px;
//}
//
//.confirm__paragraph {
//  margin-top: 30px;
//  display: flex;
//  flex-direction: column;
//  justify-content: space-between;
//  @media screen and (max-height: 1200px) and (min-width: 1000px) {
//    margin: 30px 8px 0 8px;
//  }
//}
//
//.paragraph__title {
//  margin: 8px 0;
//}
//
//.confirm__submit {
//  text-align: center;
//  text-decoration: none;
//  display: block;
//  padding: 13px 0;
//  margin-top: 32px;
//  background-color: #7ded72;
//  color: #2c2e60;
//  border: none;
//  border-radius: 10px;
//  width: 100%;
//  text-transform: uppercase;
//  font-weight: bold;
//  font-size: 1rem;
//  cursor: pointer;
//}
//
//.confirm__form {
//  text-align: center;
//  padding-bottom: 32px;
//}
</style>
