<template>
  <div class="page">
    <div class="header">
      <img class="logo" src="../assets/koinx-logo-branca.png" alt="koin logomarca"/>
    </div>
    <div class="container">
      <div class="fail-dialog">
        <div class="icon-circle-container">
          <img src="../assets/iconSad.svg" alt="icone triste" class="iconsad">
        </div>
        <p class="fail-title">Desculpe mas não conseguimos aprovar a sua solicitação no momento.</p>
        <p class="redirect-title">Você será redirecionado novamente ao site da Nuvemshop em <strong>{{ timerCount }} segundos.</strong></p>
        <p class="redirect-subtitle">Se você não for redirecionado automaticamente
          <a class="redirect-link"  v-on:click="redirectToUrl">clique aqui.</a></p>
      </div>
    </div>
  </div>
</template>

<script>

import ContentWrapper from "@/components/ui/ContentWrapper.vue";
import TheFooter from '@/components/ui/TheFooter.vue';
import Loader from "@/components/ui/Loader";


export default {
  components: { Loader, ContentWrapper, TheFooter },
  data() {
    return {
      timerCount: 10,
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          if (this.$route.query.r) {
            window.location.href = this.$route.query.r.toString()
          }
        }

      },
      immediate: true
    }
  },
  methods: {
    redirectToUrl: function () {
      console.log(this.$route.query.r.toString())
      window.location.href = this.$route.query.r.toString()
    }
  }

};
</script>

<style lang="scss">
.iconsad {
  width: 50px;
}
.fail-dialog {
  display: flex;
  padding: 32px;
  margin-top: 100px;
  flex-direction: column;
  background: #fff;
  position: relative;
  width: 100%;
  height: 217px;
  @media screen and (min-width: 450px) {
    width: 438px;
    border-radius: 10px;
  }

}

.icon-circle-container {
  width: 74px;
  height: 74px;
  border-radius: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  position:absolute;
  left:0;
  right:0;
  top: -32px;
  margin-left:auto;
  margin-right:auto;
  border: 5px solid white;
  background: #fef2cd
}

.fail-title {
  color: rgba(251, 188, 5, 1);
  margin-bottom: 8px;
  margin-top: 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;

}

.redirect-title {
  color: #666666;
  margin-bottom: 16px;
  font-size: 13px;
  text-align: center;
}

.redirect-subtitle {
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  max-width: 310px;
  margin: 0 auto;
}

.redirect-link {
  text-decoration: none;
  color: #4185f4;
  cursor: pointer;
}
</style>