<template>
  <content-wrapper>
    <div class="login__container">
      <img class="login__logo" src="@/assets/koin-logo.png" alt="koin logo"/>
      <div class="login__nuvemshop">Entrar com nuvemshop</div>
      <loader v-if="loading" />
      <a
        v-else
        :class="['login__button', link === '' ? 'login__button--disabled' : '']"
        :href="link"
        >Login</a
      >
    </div>
  </content-wrapper>
  <the-footer />
</template>

<script>
import axios from "axios";
import Loader from "@/components/ui/Loader.vue";
import ContentWrapper from "@/components/ui/ContentWrapper.vue";
import TheFooter from "@/components/ui/TheFooter.vue";
import {resolveError} from '@/utils/errutils'

export default {
  components: { Loader, ContentWrapper, TheFooter },
  data() {
    return {
      csrf: "",
      loading: false,
      retry: 0,
      fail: false,
    };
  },
  mounted() {
    this.fetchPreSession();
  },
  methods: {
    async fetchPreSession() {
      this.loading = true;
      try {
        const { data } = await axios.get("/tokens/pre");
        if (data.token) {
          this.csrf = data.token;
        }
      } catch (e) {
        console.error(e);
        this.retry++;
        if (this.retry <= 3) {
          this.fetchPreSession();
        } else {
          this.fail = true;
          let resolvedError = resolveError(e.response);
          this.$alert.setAlert(resolvedError.text, resolvedError.interval);
          if (resolvedError.redirect) {
            this.$router.push(resolvedError.redirect);
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    link() {
      if (this.fail || !this.csrf) return "";
        return `https://www.nuvemshop.com.br/apps/${process.env.VUE_APP_CODE}/authorize?state=${this.csrf}`;
    },
  },
};
</script>

<style lang="scss">
.login__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 500px;
  padding: 32px;
  box-sizing: border-box;
  @media screen and (min-width: 450px) {
    width: 400px;
  }
}

.login__button {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  height: 54px;
  background: #13ea13;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  color: #000000;
  &--disabled {
    opacity: 0.7;
    filter: grayscale(1);
  }
}

.login__nuvemshop {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-size: 20px;
  text-align: center;

  color: #666666;
}

.login__logo {
  width: 100%;
  max-width: 140px;
  padding: 18px 0;
}
</style>