<template>

    <div class="header">
      <img class="logo" src="../assets/koinx-logo-branca.png" alt="koin logomarca"/>
    </div>
    <div class="container">
    <h1>TUTORIAL</h1>
      <div class="fail-dialog">
        <div class="container">
      <br> 
      <br> 
        <div class="content">
      <p><b style="color:red">Atenção: </b>Caso essa opção não seja ativada, não será possível efetuar o pagamento com esse aplicativo.</p>
      <br> 
          

      <h3>1ª Passo</h3>
    <hr>
      <br>
      <p>Ao acessar sua loja, você encontrará esses menus. Você deve clicar no botão de "Configurações", é o último da lista.</p>
      <small>Vide imagem a baixo</small>
      <br>
      <br>
      <img  class="tutorial_image" src="@/assets/tutorial_1.png" alt="Tutorial">
      <br>
      <br>
      <br>
      <br>
      <h3>2º Passo</h3>
    <hr>
      <br>
      <p>Na tela seguinte, você deve acessar o menu "Opções de checkout".</p>
      <small>Vide imagem a baixo</small>
      <br>
      <br>
      <img  class="tutorial_image" src="@/assets/tutorial_2.png" alt="Tutorial">
      <br>
      <br>
    
      <br>
      <br>
      <h3>3º Passo</h3>
    <hr>
      <br>
      <p>Na tela seguinte, você deve marcar a opção "Pedir o telefone de contato" caso não esteja ativado.</p>
      <small>Vide imagem a baixo</small>
      <br>
      <br>
      <img  class="tutorial_image" src="@/assets/tutorial_3.png" alt="Tutorial">
      <br>
      <br>
    
      <br>
      <br>
      <h3>4º Passo</h3>
    <hr>
      <br>
      <p>Salve as alterações rolando a tela até o fim e clicando no botão "Salvar alterações".</p>
      <small>Vide imagem a baixo</small>
      <br>
      <img  class="tutorial_image last" src="@/assets/tutorial_4.png" alt="Tutorial" >
      <br>
      <br> 
      </div>
    </div>
      </div>
    <button class="floating-button" @click="goBack">Voltar</button>
    </div>
</template>

<script>

import ContentWrapper from "@/components/ui/ContentWrapper.vue";
import TheFooter from '@/components/ui/TheFooter.vue';
import Loader from "@/components/ui/Loader";


export default {
  components: { Loader, ContentWrapper, TheFooter },
  data() {
    return {
      timerCount: 10,
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          if (this.$route.query.r) {
            window.location.href = this.$route.query.r.toString()
          }
        }

      },
      immediate: true
    }
  },
  methods: {
    redirectToUrl: function () { 
      window.location.href = this.$route.query.r.toString()
    },
    goBack() { 
      this.$router.go(-1); 
    },
  }

};
</script>

<style lang="scss" scoped>
.last {
  margin-bottom: 70px;
}
.floating-button {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%) translateY(0);
    background-color: #245b48;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 26px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 200px;
    height: 45px;
    text-transform: uppercase;
}

#app {
  background-color: white!important;
}
.tutorial_image {
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
  border-radius: 15px;
}
.content {
  text-align: left;
}
.container {
  background-color: white;
}
.fail-dialog {
  display: flex;
  padding: 32px; 
  flex-direction: column;
  background: #fff;
  position: relative;
  width: 100%;
  height: 217px;
  margin-top: 10px!important;
  @media screen and (min-width: 450px) {
    width: 80%;
    border-radius: 10px;
  }

}
.logo {
  width: 200px;
}
img {
  width: 100%;
} 
</style>