<template>
  <content-wrapper>
    <div class="authorize">
      <img class="authorize__logo" src="@/assets/koin-logo.png" alt="koin logo" />
      <loader />
      <div>
        <p class="authorize__processing-text">{{ text }}</p>
      </div>
    </div>
  </content-wrapper>
  <the-footer />
</template>

<script>
import ContentWrapper from "@/components/ui/ContentWrapper.vue";
import Loader from "@/components/ui/Loader.vue";
import axios from "axios";
import TheFooter from '@/components/ui/TheFooter.vue';
import {resolveError} from '@/utils/errutils'

const processingTexts = [
  "Requisitando informações para nuvemshop...",
  "Processsando informações da nuvemshop...",
  "Criando método de pagamento...",
  "Preparando configurações...",
];

export default {
  name: "",
  components: {
    Loader,
    ContentWrapper,
    TheFooter,
  },
  data() {
    return {
      index: 0,
      interval: null,
    };
  },
  mounted() {
    if (this.interval == null) {
      this.interval = setInterval(this.handleInterval.bind(this), 2000);
    }

    this.postCode(this.$route.query.code, this.$route.query.state || "");
  },
  methods: {
    handleInterval() {
      if (this.index === processingTexts.length - 1) {
        clearInterval(this.interval);
        return;
      }

      this.index++;
    },
    async postCode(code, token) {
      try {
        let { data } = await axios.post("/authorizations", { code, token });
        if (data.session_id) {
          this.$session.setSession(data.session_id);
          this.$router.push(`/configure`);
        }
      } catch (e) {
        let resolvedError = resolveError(e.response)
        this.$alert.setAlert(resolvedError.text, resolvedError.interval)
        if (resolvedError.redirect) {
          this.$router.push(resolvedError.redirect)
        }
      }
    },
  },
  computed: {
    text() {
      return processingTexts[this.index];
    },
  },
};
</script>

<style lang="scss">
.authorize {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  @media screen and (min-width: 450px) {
    max-width: 400px;
    height: 500px;
  }
}

.authorize__processing-text {
  font-size: 20px;
  min-height: 54px;
  color: #666666;
  @media screen and (min-width: 450px) {
    font-size: 18px;
  }
}

.authorize__logo {
  width: 140px;
  padding: 18px 0;
}
</style>
