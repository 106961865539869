import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Authorize from '../views/Authorize.vue'
import Configure from '../views/Configure.vue'
import Confirm from '../views/Confirm.vue'
import Login from '../views/Login.vue'
import Help from '../views/Help.vue'
import Failed from '../views/Failed.vue'
import { instance as session } from '@/plugins/session'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/authorize',
    name: 'Authorize',
    component: Authorize
  },
  {
    path: '/configure',
    name: 'Configure',
    component: Configure
  },
  {
    path: '/confirm',
    name: 'Confirm',
    component: Confirm,
  },
  {
    path: '/failed',
    name: 'Failed',
    component: Failed,
  },
  {
    path: '/help',
    name: 'Ajuda',
    component: Help,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const existentRoutes = ['/confirm', '/authorize', '/configure', '/login', '/failed', '/help']

router.beforeEach((to, from, next) => {

  let index = existentRoutes.findIndex(x => to.path == x)

  if (index < 0) {
  window.location.href = 'https://www.koin.com.br/bnplb2b/'
  return
  }

  if (to.path === "/authorize" && !to.query.code) {
    window.location.href = 'https://www.koin.com.br/bnplb2b/'
    return
  }

  if (to.path === "/configure" && !session.getSession()) {
    next({path: '/login'})
    return
  }

  next()
})

export default router
