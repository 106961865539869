import { AxiosResponse } from "axios";

type ErrorResolved = {
    redirect?: string;
    interval?: number;
    text: string;
}

export function resolveError(response?: AxiosResponse): ErrorResolved {
    if (!response?.data?.code || !response?.data?.message) return {text: 'Não foi possível contatar o servidor'};
    const code = response.data.code;
    const message = response.data.message;
    switch(code) {
        case 'wrong_credentials':
            return {text: 'E-mail e/ou senha inválidos', interval: 4}
        case 'session_expired':
            return {text: 'Sessão expirada', redirect: '/login'}
        case 'database_error':
            return {text: 'Erro no servidor, tente novamente', interval: 4}
        case 'third_party_request_error':
            return {text: 'Erro no servidor, tente novamente', interval: 4}
        case 'unknown_error':
            return {text: message, interval: 6}
    }

    return {text: 'Erro desconhecido'}
}