<template>
    <div v-show="hasError" class="alert">
        {{text}}
    </div>
</template>

<script>

export default {
    computed: {
        text() {
            return this.$alert.getAlert()
        },
        hasError() {
            return this.text.length > 0
        }
    }
}
</script>

<style lang="scss">
.alert {
    position: fixed;
    bottom: 15vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: #BF4B44;
    color: #fff;
    text-align: center;
    width: 50vw;
    max-width: 300px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 15px;
    transition: all 0.5s;
}
</style>