<template>
  <div class="input_field">
    <label>{{ label }}</label>
    <input
      class="input"
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      :name="name"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  setup() {},
  props: {
    label: String,
    modelValue: String || Number,
    name: String,
    type: String,
    placeholder: String,
  },
};
</script>

<style lang="scss">

.input_field {
  align-items: flex-start;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #666666;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
}


.input {
  height: 40px;
  appearance: none;
  background: #F3F3F3;
  border: none;
  color: rgb(106, 106, 106);
  flex: 1 1 0;
  font-size: 16px;
  line-height: 18px;
  padding: 10px 10px 10px 18px;
  width: 100%;
  display: inline-block;
  white-space: normal;
  vertical-align: middle;
  &::placeholder {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    line-height: 18px;
    /* identical to box height */
    color: #999999;
  }
}
</style>
